/* WhereYouBelong.css */

.p-1 {
    background-color: #d9d9d9;
    font-family:Arial, Helvetica, sans-serif,sans-serif;
    font-size: 18px;
    
  }
  
  .input-style,
  .textarea-style {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
    border-radius: 10px;
  }
  
  .textarea-style {
    resize: vertical;
    margin-bottom: 10px;
  }
  
  .text-center {
    text-align: center;
    padding: 10px;
  }
  