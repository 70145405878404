/* Success.module.css */

.cardContainer {
    background-color: white;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 5px 20px;
    display: flex;
    justify-content: space-evenly;
  }
  
  @media (max-width: 768px) {
    .cardContainer {
      max-width: 90%; /* Adjust as needed for smaller screens */
    }
  }
  