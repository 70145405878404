
.fiber-service {
    padding: 15px;
  }
  
  .fiber-service .plans {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .fiber-service .plan {
    border: 1px solid #ddd;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .fiber-service .plan h2 {
    margin-top: 0;
  }
  
  .fiber-service .plan p {
    margin: 5px 0;
  }

  .align-items-center {
    align-items: center !important;
  }

  /* .hover-title {
    position: relative;
    /* display: inline-block; */
    /* cursor: pointer;
  }
  
  .hover-title::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background-color: orange; 
    transition: width 0.3s;
    margin-top: 5px;
  }
  
  .hover-title:hover::after {
    width: 100%;
  } */ 
   
  .custom-hr{
    border: 0;
  height: 3px;
  background-color: orange; 
   } 
  
  /* .img-fluid {
    max-width: 100%;
    height: 60vh;
  }
   */
  