.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .page-item {
    margin: 0 2px;
  }
  
  .page-item.disabled .page-link, .page-item.active .page-link {
    pointer-events: none;
  }
  
  @media (max-width: 768px) {
    .pagination .page-link {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 576px) {
    .pagination {
      font-size: 0.75rem;
    }
  
    .pagination .page-link {
      padding: 0.25rem 0.5rem;
    }
  }
  