.navbar{
  
  font-family:Arial, Helvetica, sans-serif,sans-serif;
  font-size: 20px;

}
.nav-link {
  position: relative;
}

.navbar-nav .nav-link {
  margin-right: 10px; 
}

.nav-link:hover {
  color: #007bff; 
}
.underline {
  position: absolute;
  bottom: -1px; 
  left: 0;
  width: 0;
  height: 2px; 
  background-color: transparent; 
  transition: width 0.3s ease; 
}

.nav-link:hover .underline {
  width: 100%; 
  background-color:orange; 
}

.navbar-brand {
  float: left;
  padding: 0px 65px;
  
}

.navbar-end{
  margin-right: 50px;

}