.review-rating .star {
    color: gold;
  }    

.container {
  padding: 20px;
  border-radius: 10px; 
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  */
}

.review {
  margin: 20px 0; 
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1); 
  border-radius: 5px; 
}

.reviewer-details {
  margin-top: 10px; 
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 25px;
}

.review-name {
  color: orange
}
.button {
  background-color: orange;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: darkorange;
}




